html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
}

@font-face {
  font-family: jf-openhuninn;
  font-display: swap;
  src: url(https://cdn.jsdelivr.net/gh/justfont/open-huninn-font@master/font/jf-openhuninn-2.0.ttf);
}

@font-face {
  font-family: edukai;
  font-display: swap;
  src: url(https://cdn.jsdelivr.net/gh/Jiehong/TW-fonts@master/edukai-4.0.ttf);
}

body {
  min-width: 100%;
}

#__next {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

#__next > div:not(.fresnel-container),
#__next > main {
  flex-grow: 1;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.firebase-emulator-warning {
  display: none;
}

[type=button], button {
  -webkit-appearance: none;
}
